<template>
    <div class="container" id="navigation">
        <nav class="navbar justify-content-between">
            <router-link to="/">
              <img :src="getImage(this.$store.state.project.logo,'logos')" width="140" height="140" alt="iCity-Logo" id="headerLogo">
            </router-link>
            <div @click="setCookie" class="notification" data-toggle="modal" data-target="#notification">
                <a href="#"><img width="20" height="20" src="@/assets/images/icons/bell.svg" alt="Notification"></a>
                <span class="ind" v-show="notificationBellActive">1</span>
            </div>
        </nav>
    </div>
    <div class="modal fade" id="notification" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="notification.title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <span v-html="notification.content"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueCookies from 'vue-cookies'
import {mapGetters, mapState} from "vuex";

export default {
    name: 'navigation',

    data(){
      return {
          notificationBellActive:false,
      }
    },
    methods:{
      setCookie(){
          VueCookies.set(this.notification.cookie_update,"true",this.notification.duration+"d");
          this.notificationBellActive=false;
      },
    },

    renderTriggered(){

      if(VueCookies.isKey( this.notification.cookie_update )){
        this.notificationBellActive=false;
      }else{
        this.notificationBellActive=true;
        // console.log(this.notification.cookie_update)
        VueCookies.keys().forEach(cookie => {
            cookie.includes('notification') ? VueCookies.remove(cookie) : ''
        })
      }

    },
    computed:{
      ...mapGetters(['getImage']),
      ...mapState(['project','notification'])
    }
}
</script>

<style>
    #navigation{
        position: absolute;
        left: 0;
        right: 0;
        top: 2vh;
    }
    #headerLogo{max-width: 6.5rem;height:auto;z-index: 100;position:relative;}    
    
    .notification{
        background-color:var(--mainColor);
        padding:15px;
        border-radius: 50%;
        transition: all 0.4s;
        z-index: 100;
        position:relative;
    }
    
    .notification a{display:flex;width:20px;height: 20px;}
    
    .notification .ind{
        background-color:red;
        color:white;
        width:20px;
        height:20px;
        border-radius: 50%;
        position: absolute;
        right:1rem;
        text-align: center;
        font-size:0.9rem;
        font-family: sans-serif;
        font-weight: 700;
    }
    .notification:hover{box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);cursor: pointer;}
    #notification .modal-header{
        border-bottom:0;
    }
    #notification .modal-header h5{
        font-size:2rem;
        color:var(--mainColor);
    }
    #notification .close{color:var(--mainColor);}
    #notification .modal-content{
        border-radius: 22px;
        padding: 10px 25px;
        box-shadow: inset 407px 0px 0px -400px #db6060;
        background-color:white !important;
        min-height: max-content;
    }
    #notification .modal-body{
        color:black;
        padding:0px 20px 20px 20px !important;
        font-size:1.5rem;
        font-weight: 500;  
        color:var(--mainColor);  
    }
</style>