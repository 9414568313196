<template>
  <div class="modal fade" :id="'modal'+data.id" tabindex="-1" role="dialog" aria-hidden="true" @click="videoHandle">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <!---- Modal Header Beginning ---->
        <div class="modal-header">
          <h5 class="modal-title">{{ data.title }}</h5>

          <div class="tabsContainer">
            <ul class="nav nav-tabs" role="tablist" v-if="data.tabs.length > 1">
              <li class="nav-item" v-for="(tab,index) in data.tabs" :key="tab.id">
                <a class="nav-link" :class="index === 0 ? 'active' : '' "
                   :id="'tab-'+tab.id"
                   data-toggle="tab"
                   :href="'#tab'+tab.id"
                   role="tab"
                   :aria-controls="'tab'+tab.id"
                   >
                  {{ tab.name }}
                </a>
              </li>
            </ul>
          </div>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!---- Club Park Gallery Modal Fixed Beginning ---->
        <div class="modal-body">

          <div class="tab-content">
            <div  v-for="(tab,index) in data.tabs" :key="tab.id" :id="'tab'+tab.id"
                  class="tab-pane fade" :class=" index === 0 ? 'active show' : ''"
                  role="tabpanel" :aria-labelledby="'tab-'+tab.id">

              <div :id="'name'+tab.id" class="carousel slide" data-ride="false">

                <div class="carousel-inner">

                  <template v-if="tab.type === 'video'" >
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe loading="lazy" ref="YTVideo" class="embed-responsive-item" :src="tab.video_url" allowfullscreen></iframe>
                    </div>
                  </template>

                  <template v-if="tab.type === 'image'" >
                    <div class="carousel-item" :class="index === 0 ? 'active' : '' " style=""
                         v-for="(media,index) in tab.media" :key="media.id">
                      <img class="d-block w-100" :src="getImage(media.img_name,'tabs')" alt="First slide"/>
                    </div>
                  </template>
                  <template v-if="tab.type === 'caption'" >
                    <div class="carousel-item" :class="index === 0 ? 'active' : '' " style=""
                         v-for="(media,index) in tab.media" :key="media.id">
                      <img class="d-block w-100" :src="getImage(media.img_name,'tabs')" alt="First slide"/>
                      <div class="tabCard">
                        <h5 v-if="media.title">{{ media.title }}</h5>
                        <p v-if="media.description" v-html="media.description"></p>
                      </div>
                    </div>
                  </template>

                </div>

                <div class="tabCard" v-if="(tab.type !== 'caption')&&(tab.title || tab.description)">
                  <h5 v-if="tab.title">{{ tab.title }}</h5>
                  <p v-if="tab.description" v-html="tab.description"></p>
                </div>

                <template v-if="tab.type !== 'video' && tab.media.length > 1 ">
                  <a class="carousel-control-prev" :href="'#name'+tab.id" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" :href="'#name'+tab.id" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </template>

              </div>

            </div>
          </div>

        </div>

        </div>
        <!---- Club Park Gallery Modal Fixed End ---->

      </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props:{
    data:{},
  },
  computed:{
    ...mapGetters(['getImage'])
  }
}
</script>

<style>
/* Tabs UI */
.tabsContainer{display:flex;margin:auto;align-items: center;}
.tabsContainer ul li a{padding:0.5rem; line-height:1.6rem;}
.tabsContainer li{margin:0 0.8rem;}
.tabsContainer a{color:var(--mainColor); font-weight: 600;border-radius: 50px !important;font-size: 1rem;cursor: pointer;}
.tabsContainer a:hover:not(.active){background-color:lightgray;}

.tabsContainer a:hover:not(.active){color:#293d4a !important;}
.tabsContainer a.active{ color:white !important; background-color:var(--mainColor) !important; }
.tabsContainer a.active:hover{background-color:#293d4a !important;}
.tabsContainer .nav-tabs{border:0;}


.modal-header{align-items: center ;border:0; background-color:white;}
.modal-body{padding:0;}
.modal-content{border-radius: 0;min-height:40vh;background-color: black;}
.modal-title{color:black;font-weight: 700;font-size:1.6rem;}
.close{opacity:1;font-size:2.1rem;padding:0.6rem 1.5rem;margin-top: -1.3rem !important; margin-left:0 !important;text-shadow: none;transition:all 0.5s ease;}
.close:hover{transform:scale(1.2);}
.modal-dialog{
  min-width:55vw !important;
}
.carousel-item img{
  object-fit:contain;
  max-height:60vh;
}

.carousel-control-next, .carousel-control-prev{
  opacity:0.7;
}
.carousel-control-next span, .carousel-control-prev span{
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.8));filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.8));
  width:25px;height:25px;
}

.tabCard{color:black;padding:0.9rem 2rem;background-color:white;}
.tabCard h5{font-size:2.5rem;font-weight: 700;}
.tabCard p{font-size:1.3rem;font-weight: 400;}
</style>